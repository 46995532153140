import {gql} from "@apollo/client";

const GET_USERS = gql`
query GetUsers($orderBy: [userFilter_order], $pageSize: Int!, $after: String, $before: String, $simplesearch: String){
  users(order: $orderBy, first: $pageSize, after: $after, before: $before, simplesearch: $simplesearch) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        id
        firstName
        lastName
        email
        active
        lastLoginAtAgo
        roles
      }
    }
  }
}
`;

const UPDATE_USER = gql`
mutation ($id: ID!, $firstName: String, $lastName: String, $active: Boolean, $roles: Iterable, $password: String) {
  updateuser(
    input: {id: $id, firstName: $firstName, lastName: $lastName, active: $active, roles: $roles, password: $password}
  ) {
    user {
      id
      firstName
      lastName
      email
      active
      lastLogin
      roles
    }
  }
}
`;

const GET_USER = gql`
query ($id: ID!) {
  user(id: $id) {
    id
    firstName
    lastName
    email
    active
    roles
    lastLoginAtAgo
  }
}
`;

const DELETE_USER = gql`
mutation ($id: ID!) {
  deleteuser(input: {id: $id}) {
    clientMutationId
  }
}
`;

export {GET_USERS, UPDATE_USER, DELETE_USER, GET_USER};