import React, {useEffect} from "react";
import Paper from '@material-ui/core/Paper';
import {ViewState} from '@devexpress/dx-react-scheduler';
import {GET_EVENTS} from "../../modules/calendar/_queries";
import {
    Scheduler,
    DayView,
    TodayButton,
    ViewSwitcher,
    WeekView,
    MonthView,
    Appointments,
    DateNavigator,
    AppointmentTooltip,
    Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui';
import i18n from "i18next";
import {useDispatch} from "react-redux";
import {DateTime} from "luxon";
import {useQuery} from "@apollo/client";

const currentDate = DateTime.now().toFormat('yyyy-MM-dd');
const demoData = [
    {startDate: '2021-07-07T14:45', endDate: '2021-07-07T16:45', title: 'Meeting'},
    {startDate: '2021-07-07T12:00', endDate: '2021-07-07T13:00', title: 'Go to a gym'},
];

const messages = {
    todayButton: {
        today: i18n.t('today')
    }
}

const extractGames = ({games}) => {
    return games.edges.map(({node}) => {
        return {
            title: node.summary,
            startDate: node.start,
            endDate: node.end
        }
    });
}

const Calendar = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE_TITLE',
            payload: i18n.t("Matches")
        });
    });

    let {data, error, loading} = useQuery(GET_EVENTS);

    if (error) return (<pre>{error.message}</pre>);

    const transform = (data) => {
        return [...extractGames(data)];
    }

    const schedulerData = (!loading) ? transform(data) : demoData;

    return (
        <Paper>
            <Scheduler data={schedulerData} locale={i18n.language}>
                <ViewState
                    defaultCurrentDate={currentDate} defaultCurrentViewName="Month" locale={i18n.language}
                />
                <DayView startDayHour={11}/>
                <WeekView startDayHour={11}/>
                <MonthView startDayHour={11}/>
                <Toolbar/>
                <DateNavigator/>
                <TodayButton messages={messages.todayButton}/>
                <ViewSwitcher/>
                <Appointments/>
                <AppointmentTooltip showCloseButton />
            </Scheduler>
        </Paper>
    )
}

export default Calendar;