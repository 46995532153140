import React from "react";
import LoginForm from "../../components/login-form/LoginForm";
import {Redirect} from "react-router-dom";
import LoginLayout from "../../components/layout/Login";
import {withTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';

function Login(props) {
    const pageTitle = props.t('Login');
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.isAuthenticated);

    dispatch({
        type: 'SET_PAGE_TITLE',
        payload: pageTitle
    });

    const renderPage = () => {
        return (
            <LoginLayout>
                <LoginForm/>
            </LoginLayout>
        );
    }

    return <React.Fragment>
        {!isAuthenticated ?
            renderPage() : <Redirect from="/login" to="/"/>
        }
    </React.Fragment>
}

export default withTranslation()(Login);