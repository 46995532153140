import {createStore} from 'redux';

const appName = process.env.REACT_APP_NAME;

const initialState = {
    user: null,
    pageTitle: '',
    isAuthenticated: false,
    isLoading: false,
    errors: [],
    appName
}

const reducer = (state = initialState, action) => {
    if (action.type === 'SET_LOADING') {
        return Object.assign({}, state, {
            isLoading: action.payload
        });
    }

    if (action.type === 'SET_USER') {
        return Object.assign({}, state, {
            user: action.payload
        });
    }

    if (action.type === 'SET_IS_AUTHENTICATED') {
        return Object.assign({}, state, {
            isAuthenticated: action.payload
        });
    }

    if (action.type === 'SET_PAGE_TITLE') {
        document.title = `${action.payload} | ${appName}`
        return Object.assign({}, state, {
            pageTitle: action.payload
        });
    }
    return state;
}

const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;