import React from "react";
import i18n from "i18next";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {IconButton, Tooltip} from "@material-ui/core";

function RenderActiveCell(props) {
    const btn = {
        label: i18n.t('clickDisableUser'),
        icon: <CheckIcon fontSize={"inherit"}/>,
        handler: props.handleDisable
    }

    if (!props.item.row.active) {
        btn.label = i18n.t('clickEnableUser');
        btn.icon = <ClearIcon fontSize={"inherit"}/>;
        btn.handler = props.handleEnable;
    }

    return (
        <Tooltip title={btn.label}>
            <IconButton aria-label={btn.label} color="default" size="small"
                        onClick={() => {
                            btn.handler(props.item.row)
                        }}
            >
                {btn.icon}
            </IconButton>
        </Tooltip>
    )
}

export default RenderActiveCell;