import React from "react";
import { connect } from 'react-redux';
import LogoutAction from "../../components/logout/Logout";

class Logout extends React.Component {
    render() {
        return <LogoutAction />;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        isAuthenticated: state.isAuthenticated
    };
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);