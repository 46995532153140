class _userType {
    id = ""
    firstName = ""
    lastName = ""
    email = ""
    active = false;
    lastLoginAtAgo = ""
    roles = []
}

export default _userType;