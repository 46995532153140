import axios from "axios";
import Auth from "./Auth";

class APIService {
    constructor() {
        this.endpoint = process.env.REACT_APP_API_ENDPOINT;
    }

    get jwt() {
        return Auth.getToken();
    }

    get config() {
        return {
            headers: { Authorization: `Bearer ${this.jwt}` }
        }
    }

    buildURL(action) {
        return `${this.endpoint}${action}`
    }

    login(username, password) {
        return axios
            .post(this.buildURL('/api/login'), {
                username,
                password
            });
    }

    getUser(rir) {
        return axios.get(this.buildURL(rir), this.config);
    }

    me() {
        return axios.get(this.buildURL('/me'), this.config);
    }
}

const API = new APIService();

export default API;