import React from "react";
import {Link, Typography} from "@material-ui/core";
import {withTranslation} from 'react-i18next';

function Copyright(props) {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {props.t('copyright') + ' '}
            <Link color="inherit" href={process.env.REACT_APP_URL}>
                {process.env.REACT_APP_NAME}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default withTranslation()(Copyright);