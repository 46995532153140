import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import Form from "./form";
import {
    AppBar,
    Container,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core/styles";
import i18n from "i18next";
import {vsprintf} from "sprintf-js";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        justifyContent: "center",
        padding: theme.spacing(5, 2)
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditDialog(props) {
    const classes = useStyles();

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    const [user, setUser] = React.useState({});

    return (
        <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {vsprintf(i18n.t('editingUser'), `${user.firstName} ${user.lastName}`)}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" className={classes.container}>
                <Form user={user}/>
            </Container>
        </Dialog>
    )
}

EditDialog.propTypes = {
    user: PropTypes.object
}

export default EditDialog;