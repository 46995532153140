import {gql} from "@apollo/client";

const GET_EVENTS = gql`
query($orderBy: [gameFilter_order]){
  games(order: $orderBy, first: 1000){
    totalCount
    pageInfo{
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges{
      cursor
      node{
        id
        start
        end
        summary
        description
        location
      }
    }
  }
}
`;

export {GET_EVENTS};