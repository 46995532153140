import Api from './Api';

class Auth {
    handleUserAuthenticated(token) {
        sessionStorage.setItem('auth_jwt', token);
        document.location = '/';
    }

    loadCurrentUser() {
        return Api.me();
    }

    handleLogout() {
        sessionStorage.removeItem('auth_jwt');
        document.location = '/login';
    }

    getToken() {
        return sessionStorage.getItem('auth_jwt');
    }
}

export default new Auth();