import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {useDispatch} from 'react-redux'
import {Container} from "@material-ui/core";

export default function Dashboard() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE_TITLE',
            payload: "Dashboard"
        });
    })

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={9}>
                    <Paper>
                        Charts
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper>
                        Deposits
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        Orders
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}