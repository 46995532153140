import React from "react";
import Auth from "../../services/Auth";

class Logout extends React.Component {

    render() {

        return (
            <React.Fragment>
                {Auth.handleLogout()}
            </React.Fragment>
        )
    }
}

export default Logout;