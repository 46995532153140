import React from "react";
import {connect} from 'react-redux';
import {compose} from "redux";
import {withTranslation} from 'react-i18next';

import "./style.scss";

import Auth from '../../services/Auth';
import Api from '../../services/Api';
import {Button, Checkbox, FormControlLabel, TextField, withStyles} from "@material-ui/core";
import {Alert} from '@material-ui/lab';

const styles = theme => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
});


class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    set isLoading(value) {
        this.mutateRemoteState('SET_LOADING', value);
    }

    set error(value) {
        this.mutateLocalState('error', value)
    }

    get username() {
        return this.state.username;
    }

    get password() {
        return this.state.password;
    }

    mutateRemoteState(type, payload) {
        this.props.dispatch({
            type,
            payload
        })
    }

    mutateLocalState(property, payload) {
        const newState = this.state;
        newState[property] = payload;
        this.setState(newState);
    }

    handleChange(event) {
        const field = event.target.getAttribute('name');
        this.mutateLocalState(field, event.target.value);
    }

    handleSubmit(event) {
        this.isLoading = true;

        Api.login(this.username, this.password)
            .then(response => {
                this.handleUserAuthenticated(response.data);
            }).catch(error => {
            if (error.response.data.message) {
                this.error = error.response.data.message;
            } else {
                this.error = 'Unknown error';
            }
        }).finally(() => {
            this.isLoading = false;
        });

        event.preventDefault();
    }

    handleUserAuthenticated({token}) {
        Auth.handleUserAuthenticated(token);
    }

    renderError() {
        if (this.state.error) {
            return (
                <Alert severity="error">{this.state.error}</Alert>
            );
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <form onSubmit={this.handleSubmit}>
                {this.renderError()}
                <TextField label={this.props.t('emailAddress')} type={"email"} name="username"
                           onChange={this.handleChange}
                           variant="outlined" margin="normal" required fullWidth autoComplete="email" autoFocus/>
                <TextField label={this.props.t('password')} type={"password"} name="password"
                           onChange={this.handleChange} required
                           margin="normal" variant="outlined" fullWidth autoComplete="current-password"/>
                <FormControlLabel control={<Checkbox value="remember" color="primary"/>}
                                  label={this.props.t('rememberMe')}/>
                <Button className={classes.submit} fullWidth variant="contained" color="primary"
                        type="submit">{this.props.t('signIn')}</Button>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withTranslation(), withStyles(styles, {withTheme: true}))(LoginForm));