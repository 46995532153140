import React from "react";
import UsersList from "../../components/users/list";
import {Container} from "@material-ui/core";

function Users(props) {
    const renderComponent = (view) => {
        switch (view) {
            case 'list':
                return (<Container maxWidth="lg">
                    <UsersList/>;
                </Container>)
            default:
                return <p>Not found</p>
        }
    }

    return <React.Fragment>{renderComponent(props.view)}</React.Fragment>;
}

export default Users;

