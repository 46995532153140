import React from 'react';
import {Link} from "react-router-dom";

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import i18n from "i18next";

export const mainListItems = (
    <div>
        <ListItemLink icon={<DashboardIcon/>} button primary={i18n.t('Dashboard')} to="/"/>
        <ListItemLink icon={<CalendarIcon/>} button primary={i18n.t('Calendar')} to="/calendar"/>
        <ListItemLink icon={<SportsHockeyIcon/>} button primary={i18n.t('Matches')} to="/matches"/>
        <ListItemLink icon={<PeopleIcon/>} button primary={i18n.t('Users')} to="/users"/>
    </div>
);

export const secondaryListItems = (
    <div>
        <ListSubheader inset>TODO</ListSubheader>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="To be defined"/>
        </ListItem>
    </div>
);

function ListItemLink(props) {
    const {icon, primary, to} = props;

    const CustomLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <Link ref={ref} to={to} {...linkProps} />
            )),
        [to],
    );

    return (
        <li>
            <ListItem button component={CustomLink}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary}/>
            </ListItem>
        </li>
    );
}