import React from "react";
import CalendarView from "../../components/calendar/Calendar";
import {Container} from "@material-ui/core";

const Calendar = () => {
    return (
        <Container maxWidth="lg">
            <CalendarView/>
        </Container>
    )
}

export default Calendar;