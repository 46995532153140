import React, {useEffect} from "react";
import ListTable from "../list/ListTable";
import {GET_GAMES, UPDATE_GAME} from "../../modules/games/_queries";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import i18n from "i18next";
import {Button, Container, IconButton, Paper, Typography,} from "@material-ui/core";
import {useMutation, useQuery} from "@apollo/client";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {DateTime} from "luxon";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from '@material-ui/icons/Star';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
}));

const orderBy = [{start: 'ASC'}];

const pageSize = 10;

const today = DateTime.utc().toFormat('yyyy-MM-dd HH:mm');

const renderSummary = (gameData) => {
    return <Button variant="text" color="primary" onClick={() => {
        handleEdit(gameData)
    }}>{gameData.value}</Button>
}

const handleEdit = (gameData) => {
    console.log("Editing", gameData);
}

const handleDelete = (gameData) => {
    console.log('Deleting', gameData);
}

const renderDateTime = ({value}) => {
    const dateTime = DateTime.fromISO(value);
    return <Typography variant={"body2"}>
        {dateTime.toFormat('dd.MM.yyyy') + " - "}<Typography variant="overline"
                                                             color="textSecondary">{dateTime.toFormat("HH'h'mm")}</Typography>
    </Typography>;
}

const columns = [
    {
        field: 'summary',
        headerName: i18n.t('summary'),
        flex: 2,
        searchable: true,
        sortable: false,
        renderCell: renderSummary
    },
    {
        field: 'start',
        headerName: i18n.t('startDateTime'),
        flex: 1,
        searchable: false,
        renderCell: renderDateTime
    },
    {
        field: 'end',
        headerName: i18n.t('endDateTime'),
        flex: 1,
        searchable: false,
        renderCell: renderDateTime
    },
    {
        field: 'starred',
        headerName: i18n.t('Starred'),
        flex: 1,
        searchable: false,
        sortable: false,
        type: 'boolean',
    },
];

const filterList = ['Upcoming', 'Starred', 'All'];

const GamesList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE_TITLE',
            payload: i18n.t("Matches")
        });

        columns.forEach((column, index) => {
            if (column.field === 'starred') {
                columns[index].renderCell = renderStarred
            }
        });
    });

    let {data, error, loading, fetchMore, refetch, variables} = useQuery(GET_GAMES, {
        variables: {orderBy, pageSize, start: [{after: today}],},
    });

    const [updateGame] = useMutation(UPDATE_GAME);

    if (error) return (<pre>{error.message}</pre>);

    const getRowsCount = (data) => {
        return data.games.totalCount;
    }

    const getRows = (data) => {
        return data.games.edges.map((games) => {
            return games.node;
        })
    }

    const getPageInfo = () => {
        return data.games.pageInfo;
    }

    const handleFetchMore = (newVariables) => {
        fetchMore({
            variables: {
                ...variables,
                ...newVariables
            }
        });
    }

    const handleRefetch = (newVariables) => {
        refetch({
            ...variables,
            ...newVariables
        });
    }

    const transform = (data) => {
        return {
            rows: getRows(data),
            rowsCount: getRowsCount(data),
            pageInfo: getPageInfo(data)
        }
    }

    const rowsData = (!loading) ? transform(data) : {rows: [], rowsCount: 0};

    const sortModel = (() => {
        return (variables.orderBy) ? variables.orderBy.map((item) => {
            return {
                field: Object.keys(item)[0],
                sort: Object.values(item)[0]
            }
        }) : [];
    })();

    const actions = [
        {
            label: i18n.t('edit'),
            icon: <EditIcon/>,
            handler: handleEdit
        },
        {
            label: i18n.t('delete'),
            icon: <DeleteIcon/>,
            handler: handleDelete
        }
    ];

    const handleToggleStarred = ({id, value}) => {
        updateGame({variables: {id, starred: !value}});
    }

    const renderStarred = (row) => {
        return (<IconButton size="small" onClick={
            () => {
                handleToggleStarred(row)
            }
        }>{(row.value) ? <StarIcon/> : <StarBorderIcon/>}</IconButton>);
    }

    const handleFilterList = (filterName) => {
        switch (filterName) {
            case 'Upcoming':
                return handleRefetch({
                    start: [{after: today}],
                    starred: null,
                    after: null,
                    before: null
                });
            case "Starred":
                return handleRefetch({
                    starred: true,
                    start: null,
                    after: null,
                    before: null
                });
            default:
                return handleRefetch({
                    starred: null,
                    start: null,
                    after: null,
                    before: null
                });
        }
    }

    return (
        <Container maxWidth="lg">
            <Paper className={classes.paper}>
                <ListTable actions={actions} loading={loading} columns={columns}
                           pageSize={pageSize} rowsData={rowsData} sortModel={sortModel}
                           fetchMore={handleFetchMore} refetch={handleRefetch} filterList={filterList}
                           handleFilterList={handleFilterList}/>
            </Paper>
        </Container>
    )
}

export default GamesList;