import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import i18n from "i18next";

function Delete(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{i18n.t("alertOfDeletion")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {i18n.t('sureDeleteItemMSG')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleConfirm} color="primary">
                    {i18n.t('yesDelete')}
                </Button>
                <Button onClick={props.handleClose} color="default" autoFocus>
                    {i18n.t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export {Delete};