import {useMutation, useQuery} from "@apollo/client";
import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {DELETE_USER, GET_USERS, UPDATE_USER} from "../../modules/users/_queries";
import List from "../list/ListTable";
import {Link, Paper, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {Delete as DialogDelete} from "../dialogs/dialogs";
import RenderActiveCell from "./render-active-cell";
import EditDialog from "./edit-dialog";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    }
}));

const orderBy = [{id: 'ASC'}];

const pageSize = 10;

const getRolesList = (item) => {
    const rolesList = (item.row.roles.filter((role) => {
        return item.row.roles.length > 1 && role !== 'ROLE_USER'
    }).map((role) => {
        return i18n.t(role);
    }));

    return rolesList.join(', ');
}

const generateEmailLink = (item) => {
    return <Tooltip title={item.row.email}><Link href={`mailto:${item.row.email}`}>{item.row.email}</Link></Tooltip>;
}

function UsersList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE_TITLE',
            payload: props.t("Users")
        });
    });

    const [columns] = React.useState(
        [
            {field: 'firstName', headerName: i18n.t('firstName'), flex: 1, searchable: true},
            {field: 'lastName', headerName: i18n.t('lastName'), flex: 1, searchable: true},
            {
                field: 'email',
                headerName: i18n.t('emailAddress'),
                flex: 2,
                renderCell: generateEmailLink,
                searchable: true
            },
            {field: 'roles', headerName: i18n.t('role'), flex: 1, sortable: false, valueGetter: getRolesList},
            {
                field: 'active',
                sortable: false,
                headerName: i18n.t('active'),
                type: 'boolean',
                flex: .5,
                renderCell: (item) => {
                    return <RenderActiveCell item={item} handleDisable={handleDisable} handleEnable={handleEnable}/>;
                }
            },
            {field: 'lastLoginAtAgo', sortable: false, headerName: i18n.t('lastLogin'), flex: 1}
        ]
    );

    const [deleteAlertOpen, setDeleteAlertOpenOpen] = React.useState(false);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [itemDelete, setItemDelete] = React.useState({});
    const [userDataEdit, setUserDataEdit] = React.useState({});

    const getRowsCount = (data) => {
        return data.users.totalCount;
    }

    const getRows = (data) => {
        return data.users.edges.map((user) => {
            return user.node;
        })
    }

    const getPageInfo = () => {
        return data.users.pageInfo;
    }

    const handleFetchMore = (newVariables) => {
        fetchMore({
            variables: {
                ...variables,
                ...newVariables
            }
        });
    }

    const handleRefetch = (newVariables) => {
        refetch({
            ...variables,
            ...newVariables
        });
    }

    const transform = (data) => {
        return {
            rows: getRows(data),
            rowsCount: getRowsCount(data),
            pageInfo: getPageInfo(data)
        }
    }

    const handleEdit = ({row}) => {
        setEditDialogOpen(true);
        setUserDataEdit(row);
    }

    const handleDisable = ({id}) => {
        return updateUser({variables: {id, active: false}})
    }

    const handleEnable = ({id}) => {
        return updateUser({variables: {id, active: true}})
    }

    const handleDelete = (item) => {
        setDeleteAlertOpenOpen(true);
        setItemDelete(item);
    }

    const setLoading = (isLoading) => {
        dispatch({
            type: 'SET_LOADING',
            payload: isLoading
        })
    }

    const deleteItem = () => {
        setDeleteAlertOpenOpen(false);
        setLoading(true);
        deleteUser({variables: {id: itemDelete.id}}).finally(() => {
            refetch().finally(() => {
                setLoading(false);
            });
        });
    }

    const handleCloseDeleteDialog = () => {
        return setDeleteAlertOpenOpen(false);
    }

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
    }

    let {data, error, loading, fetchMore, refetch, variables} = useQuery(GET_USERS, {
        variables: {orderBy, pageSize}
    });
    const [updateUser] = useMutation(UPDATE_USER);
    const [deleteUser] = useMutation(DELETE_USER);

    if (error) return (<pre>{error.message}</pre>);

    const rowsData = (!loading) ? transform(data) : {rows: [], rowsCount: 0};

    const sortModel = (() => {
        return (variables.orderBy) ? variables.orderBy.map((item) => {
            return {
                field: Object.keys(item)[0],
                sort: Object.values(item)[0]
            }
        }) : [];
    })();

    const actions = [
        {
            label: i18n.t('edit'),
            icon: <EditIcon/>,
            handler: handleEdit
        },
        {
            label: i18n.t('delete'),
            icon: <DeleteIcon/>,
            handler: handleDelete
        }
    ];

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <List actions={actions} loading={loading} columns={columns} pageSize={pageSize} rowsData={rowsData}
                      sortModel={sortModel}
                      fetchMore={handleFetchMore} refetch={handleRefetch}/>
            </Paper>
            <DialogDelete open={deleteAlertOpen} handleClose={handleCloseDeleteDialog} handleConfirm={deleteItem}/>
            <EditDialog user={userDataEdit} open={editDialogOpen} handleClose={handleCloseEditDialog}/>
        </React.Fragment>
    )
}

export default withTranslation()(UsersList);