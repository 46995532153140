import React from "react";
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarExport,
} from "@material-ui/data-grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {},
}));

function ListToolbar() {
    const classes = useStyles();

    function handleExport() {
        alert('Exporting');
    }

    return (
        <GridToolbarContainer className={classes.root}>
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarExport onClick={handleExport}/>
        </GridToolbarContainer>
    );
}

export default ListToolbar;