import React from "react";
import Copyright from "./copyright/Copyright";

const website = 'https://google.com'; //TODO: make dynamic
const appName = 'Your App Name'; //TODO: make dynamic

function Footer() {
    return (
        <Copyright website={website} appName={appName}/>
    );
}

export default Footer;