import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {DataGrid, frFR} from "@material-ui/data-grid";
import {makeStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {Grid, IconButton, List, ListItem, ListItemText, Tooltip} from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import ListToolbar from "./ListToolbar";
import i18n from "i18next";

const vsprintf = require('sprintf-js').vsprintf;

const useStyles = makeStyles((theme) => ({
    dataGrid: {
        borderRadius: "unset",
        borderLeft: "none",
        borderRight: "none",
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-row:hover .actions': {
            opacity: 1
        }
    },
    root: {
        flexGrow: 1,
        height: '700px',
        width: '100%',
    },
    actions: {
        opacity: 0,
        outline: "none"
    }
}));

const defaultPageInfo = {
    endCursor: "",
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: ""
};

const sortingOrder = ['asc', 'desc'];

function ListTable(props) {
    const classes = useStyles();
    let delaySearch = null;
    const [pageSize, setPageSize] = React.useState(10);
    const [loading, setLoading] = React.useState(true);
    const [rowCount, setRowCount] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [sortModel, setSortModel] = React.useState([]);
    const [pageInfo, setPageInfo] = React.useState(defaultPageInfo);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [columns, _setColumns] = React.useState([]);
    const [activeFilter, setActiveFilter] = useState('Upcoming');

    useEffect(() => {
        const setColumns = (columns) => {
            if (props.actions.length > 0) {
                let index = columns.findIndex(column => column.field === "actions");
                if (index === -1) {
                    columns.push(
                        {
                            field: 'actions',
                            headerName: i18n.t('actions'),
                            sortable: false,
                            renderCell: renderActions,
                            renderHeader: () => (<div/>)
                        }
                    );
                }
            }

            _setColumns(columns);
        }

        const renderActions = (item) => {
            return (
                <div className={`actions ${classes.actions}`}>
                    {props.actions.map((action) => {
                        return (
                            <Tooltip key={`${action.label}-hint-${item.id}`} title={action.label}>
                                <IconButton key={`${action.label}-${item.id}`} aria-label={action.label} color="default"
                                            size="small"
                                            onClick={() => {
                                                action.handler(item)
                                            }}>
                                    {action.icon}
                                </IconButton>
                            </Tooltip>
                        )
                    })}
                </div>
            );
        }

        setPageSize(props.pageSize);
        setLoading(props.loading);
        setRows(props.rowsData.rows);
        setRowCount(props.rowsData.rowsCount);
        setSortModel(props.sortModel);
        setPageInfo(props.rowsData.pageInfo);
        setColumns(props.columns);
    }, [props.pageSize, props.loading, props.rowsData.rows, props.rowsData.rowsCount, props.rowsData.pageInfo, props.sortModel, props.columns, props.actions.length, classes.actions, props.actions]);

    const handleSorting = ({sortModel}) => {
        const variables = {
            before: null,
            after: null,
            orderBy: sortModel.map((item) => {
                return {
                    [item.field]: item.sort
                }
            }),
        };

        setCurrentPage(0);
        props.refetch(variables);
    }

    const handlePageChange = (clickedPage) => {
        let variables = {
            before: pageInfo.startCursor,
            after: null,
        };

        if (clickedPage.page > currentPage) {
            variables = {
                after: pageInfo.endCursor,
                before: null,
            };
        }
        setCurrentPage(clickedPage.page);
        props.refetch(variables);
    }

    const handleSearch = (what) => {
        clearTimeout(delaySearch);

        delaySearch = setTimeout(() => {
            const variables = {
                before: null,
                after: null,
                simplesearch: what
            }

            setCurrentPage(0);
            props.refetch(variables);
        }, 300);
    }

    const handleCancelSearch = () => {
        const variables = {
            before: null,
            after: null,
            simplesearch: null
        }

        setCurrentPage(0);
        props.refetch(variables);
    }

    const handleFilter = (filterName) => {
        setActiveFilter(filterName);
        setCurrentPage(0);
        props.handleFilterList(filterName);
    }

    const searchFields = (() => {
        const searchableFields = columns.filter((column) => {
            return column.searchable;
        }).map((column) => {
            return column.headerName.toLowerCase()
        });
        if (searchableFields.length > 1) {
            return `${searchableFields.slice(0, -1).join(', ')} ${i18n.t('and')} ${searchableFields.slice(-1)}`;
        } else {
            return searchableFields.join(', ');
        }
    })();

    const renderFilter = (text) => {
        const isActive = text === activeFilter;
        return (
            <ListItem selected={isActive} button key={text} onClick={() => {
                handleFilter(text)
            }}>
                <ListItemText primary={i18n.t(text)}/>
            </ListItem>
        );
    }

    const tableWithSearch = (
        <React.Fragment>
            <Grid item xs={12}>
                <SearchBar placeholder={vsprintf(props.t('search_placeholder'), searchFields)}
                           onRequestSearch={handleSearch} onChange={handleSearch}
                           onCancelSearch={handleCancelSearch}/>
            </Grid>
            <Grid item xs={12} className={classes.root}>
                <DataGrid sortingOrder={sortingOrder} localeText={frFR.props.MuiDataGrid.localeText}
                          components={{Toolbar: ListToolbar}} className={classes.dataGrid} rowCount={rowCount}
                          loading={loading} rows={rows} columns={columns} paginationMode={"server"}
                          pageSize={pageSize} onSortModelChange={handleSorting} onPageChange={handlePageChange}
                          page={currentPage} sortingMode={"server"} sortModel={sortModel} disableColumnMenu/>
            </Grid>
        </React.Fragment>
    );

    let tableWithSearchAndFilter = '';

    if (props.hasOwnProperty('filterList')) {
        const filterList = (
            <nav>
                <List disablePadding>
                    {props.filterList.map((text, index) => (
                        renderFilter(text, index)
                    ))}
                </List>
            </nav>
        );

        tableWithSearchAndFilter = (
            <React.Fragment>
                <Grid item xs={2}>
                    {filterList}
                </Grid>
                <Grid item xs={10}>
                    {tableWithSearch}
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <Grid container spacing={3} direction="row" alignItems="stretch">
            {
                props.hasOwnProperty('filterList') ? tableWithSearchAndFilter : tableWithSearch
            }
        </Grid>
    )
}

ListTable.propTypes =
    {
        loading: PropTypes.bool.isRequired,
        columns: PropTypes.arrayOf(PropTypes.object).isRequired,
        pageSize: PropTypes.number,
        rowsData: PropTypes.object.isRequired,
        fetchMore: PropTypes.func.isRequired,
        refetch: PropTypes.func.isRequired,
        sortModel: PropTypes.arrayOf(PropTypes.object),
        actions: PropTypes.arrayOf(PropTypes.object),
        isFiltering: PropTypes.bool,
        filterList: PropTypes.array,
        handleFilterList: PropTypes.func
    }

export default withTranslation()(ListTable);