import React, {useEffect} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField, useTheme
} from "@material-ui/core";
import _userType from "../../modules/users/_user-type";
import roles from "../../modules/users/_roles";
import i18n from "i18next";
import SaveIcon from '@material-ui/icons/Save';
import {GET_USER, UPDATE_USER} from "../../modules/users/_queries";
import {useDispatch} from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function EditForm(props) {
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    const [user, setUser] = React.useState(new _userType());
    const [updateUser] = useMutation(UPDATE_USER);
    const [getUser] = useLazyQuery(GET_USER);

    const handleChange = (event) => {
        const fieldName = event.target.getAttribute('name');
        let value = event.target.value;

        if (event.target.getAttribute('type') === 'checkbox') {
            value = event.target.checked;
        }

        setUser({
            ...user,
            [fieldName]: value
        })
    }

    const handleChangeSelect = (event) => {
        const {name, value} = event.target;
        setUser({
            ...user,
            [name]: value
        });
    }

    const setLoading = (value) => {
        dispatch({
            type: 'SET_LOADING',
            payload: value
        });
    }

    const handleSave = () => {
        setLoading(true);
        updateUser({variables: {id: user.id, ...user}}).finally(() => {
            getUser({variables: {id: user.id}})
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="lastName"
                        name="lastName"
                        label={i18n.t('lastName')}
                        fullWidth
                        autoComplete="family-name"
                        value={user.lastName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label={i18n.t('firstName')}
                        fullWidth
                        autoComplete="given-name"
                        value={user.firstName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label={i18n.t('emailAddress')}
                        fullWidth
                        autoComplete="email"
                        value={user.email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={user.active}
                                onChange={handleChange}
                                name="active"
                                id="active"
                            />
                        }
                        label={i18n.t('active')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel id="roles-label">{i18n.t('role')}</InputLabel>
                    <Select
                        multiple
                        required
                        labelId="roles-label"
                        id="roles"
                        name="roles"
                        value={user.roles}
                        onChange={handleChangeSelect}
                        input={<Input/>}
                        MenuProps={MenuProps}
                    >
                        {roles.map((name) => (
                            <MenuItem key={name} value={name} style={getStyles(name, user.roles, theme)}>
                                {i18n.t(name)}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12} container direction="row" justify="flex-end" alignItems="flex-end">
                    <Button autoFocus variant="contained" color="primary" size="large" onClick={handleSave}
                            startIcon={<SaveIcon/>}>
                        {i18n.t('save')}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default EditForm;