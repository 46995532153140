import React from "react";
import GamesList from "../../components/games/List";

const Games = (props) => {
    const renderComponent = (view) => {
        switch (view) {
            case 'list':
                return <GamesList/>;
            default:
                return <p>Not found</p>
        }
    }

    return <React.Fragment>{renderComponent(props.view)}</React.Fragment>;
}

export default Games;