import {gql} from "@apollo/client";

const GET_GAMES = gql`
query($orderBy: [gameFilter_order], $pageSize: Int!, $after: String, $before: String, $simplesearch: String, $start: [gameFilter_start], $starred: Boolean){
  games(order: $orderBy, first: $pageSize, after: $after, before: $before, simplesearch: $simplesearch, start: $start, starred: $starred){
    totalCount
    pageInfo{
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges{
      cursor
      node{
        id
        start
        end
        summary
        description
        location
        starred
      }
    }
  }
}
`;

const UPDATE_GAME = gql`
mutation ($id: ID!, $start: String, $end: String, $summary: String, $description: String, $location: String, $starred: Boolean) {
  updategame(
    input: {id: $id, start: $start, end: $end, summary: $summary, description: $description, location: $location, starred: $starred}
  ) {
    game {
      id
      start
      end
      summary
      description
      location
      starred
    }
  }
}
`;

export {GET_GAMES, UPDATE_GAME};