import React from "react";
import {connect} from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import store from '../store/index';
import Auth from '../services/Auth';

import Login from "./login/Login";
import Logout from "./logout/Logout";
import {Backdrop, CircularProgress, LinearProgress} from "@material-ui/core";
import AdminLayout from "../components/layout/Admin";
import {mainListItems, secondaryListItems} from '../components/sidebar/navigationItems';
import Dashboard from "./dashboard/Dashboard";
import Users from "./users/Users";
import Games from "./games/Games";
import Calendar from "./calendar/Calendar";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSession: true
        }
    }

    componentDidMount() {
        if (Auth.getToken()) {
            this.loadingSession = true;
            Auth.loadCurrentUser()
                .then(response => {
                    this.props.dispatch({
                        type: 'SET_USER',
                        payload: response.data
                    });
                    this.props.dispatch({
                        type: 'SET_IS_AUTHENTICATED',
                        payload: true
                    });
                }).catch(error => {
                if (error.response.data.error) {
                    this.error = error.response.data.error;
                } else {
                    this.error = 'Unknown error';
                }

                Auth.handleLogout();
            }).finally(() => {
                this.loadingSession = false;
            });
        } else {
            this.loadingSession = false;
        }
    }

    set isLoading(payload) {
        this.props.dispatch({
            type: 'SET_LOADING',
            payload
        });
    }

    set loadingSession(value) {
        this.setState({
            ...this.state,
            loadingSession: value
        })
    }

    get loadingSession() {
        return this.state.loadingSession;
    }

    renderDefaultComponent() {
        return <Dashboard/>
    }

    renderPage() {
        return (
            <React.Fragment>
                {this.props.isLoading ? <LinearProgress/> : null}
                <Router>
                    <Switch>
                        <Route path="/login">
                            <Login/>
                        </Route>
                        <Route path="/logout">
                            <Logout/>
                        </Route>
                        <AdminLayout mainListItems={mainListItems}
                                     secondaryListItems={secondaryListItems} notificationCount={5}>
                            <PrivateRoute path="/users">
                                <Users view={'list'}/>
                            </PrivateRoute>
                            <PrivateRoute path="/matches">
                                <Games view={'list'}/>
                            </PrivateRoute>
                            <PrivateRoute path="/calendar">
                                <Calendar/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/">{this.renderDefaultComponent()}</PrivateRoute>
                        </AdminLayout>
                    </Switch>
                </Router>
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.loadingSession ? <Backdrop open={true}><CircularProgress/></Backdrop> : this.renderPage()}
            </React.Fragment>
        );
    }

}

function PrivateRoute({children, ...rest}) {
    const state = store.getState();
    return (
        <Route
            {...rest}
            render={({location}) =>
                state.isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps = state => {
    return {
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        isLoading: state.isLoading
    };
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);